<template>
  <div class="Main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb1
      :title="$t('requestAppointment')"
      :text1="$t('requestAppointment')"
      :text="$t('home')"
    />

    <!-- Page Content -->
    <div class="content doctor-content">
      <div class="container">
        <div class="">
          <div class="dashboard-header">
            <h3>
              {{ $t("requestAppointment") }}
              <small class="text-danger">
                ( {{ $t("requestAppointmentInfo") }} )
              </small>
            </h3>
          </div>
          <div class="slot-box">
            <div class="slot-header">
              <h5>{{ getDayName($route.query.dt) }} : {{ $route.query.dt }}</h5>
              <div class="mb-0">
                <input
                  class="form-control mb-2"
                  type="date"
                  :min="currentDate"
                  v-model="date"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4 py-2" v-if="patientFamilyList.length">
                <h4 class="mb-3">{{ $t("selectBeneficiary") }}</h4>
                <select class="form-control" v-model="patientFamilyId">
                  <option value="">{{ userData.name }}</option>
                  <option
                    :value="item.patient_family_id"
                    v-for="(item, i) in patientFamilyList"
                    :key="i"
                  >
                    {{ item.name }} ( {{ item.relative_degree }} )
                  </option>
                </select>
              </div>
              <div :class="patientFamilyList.length ? 'col-lg-8' : 'col-12'">
                <div class="slot-body" v-if="availableTimes?.length">
                  <div class="py-2">
                    <h4 class="mb-3">{{ $t("selectTime") }}</h4>
                    <b-button-group size="lg" class="custom-btn-group">
                      <b-button
                        v-for="time in availableTimes"
                        :class="
                          time.id == selectedTime.id
                            ? 'bg-info'
                            : 'bg-secondary'
                        "
                        :key="time.id"
                        class="mx-1"
                        @click="selectedTime = time"
                      >
                        {{ time.time_from }}
                      </b-button>
                    </b-button-group>
                  </div>
                  <div class="py-4" v-if="selectedTime?.time_from">
                    <h6>
                      {{ getDayName($route.query.dt) }} :
                      {{ $route.query.dt }} -
                      {{ selectedTime.time_from }}
                    </h6>
                    <button
                      style="width: 140px; padding: 10px"
                      class="btn btn-primary"
                      :disabled="lodaingRequest"
                      @click="selectAppointment()"
                    >
                      <span
                        v-if="lodaingRequest"
                        class="spinner-border"
                        role="status"
                      >
                      </span>
                      <span v-else> {{ $t("request") }} </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footerindex></footerindex>
  </div>
</template>

<script>
import { checkAvailableTime } from "@/api/doctor";
import { RequestAppointment, getPatientFamily } from "@/api/patient";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      lodaing: false,
      lodaingRequest: false,
      date: "",
      title: "Request Appointment",
      text: "Home",
      text1: "Request Appointment",
      availableTimes: [],
      selectedTime: {},
      patientFamilyList: [],
      patientFamilyId: "",
    };
  },
  computed: {
    ...mapGetters(["currentDate", "userData"]),
  },
  created() {
    this.date = this.$route.query.dt;
    this.getAvailableTime();
    this.getPatientFamilyList();
  },
  methods: {
    getDayName(dateString) {
      const date = new Date(dateString);
      const options = { weekday: "long" };
      const locale = this.$i18n.locale;

      if (locale === "ar") {
        return date.toLocaleDateString("ar-EG", options);
      } else if (locale === "en") {
        return date.toLocaleDateString("en-US", options);
      } else if (locale === "fr") {
        return date.toLocaleDateString("fr-FR", options);
      }
    },
    async getAvailableTime() {
      this.lodaing = true;
      try {
        let params = {
          doctor_id: this.$route.query.dr,
          date: this.date,
        };
        let times = await checkAvailableTime(params);
        if (times) {
          this.availableTimes = times.data.data;
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.lodaing = false;
      }
    },
    async selectAppointment() {
      this.lodaingRequest = true;
      try {
        const fd = new FormData();
        fd.append("doctor_id", this.$route.query.dr);
        fd.append("date", this.$route.query.dt);
        fd.append("doctor_available_time_id", this.selectedTime.id);
        if (this.patientFamilyId) {
          fd.append("patient_family_id", this.patientFamilyId);
        }
        let res = await RequestAppointment(fd);
        if (res) {
          this.$router.push(`/search?page=1&&date=${this.currentDate}`);
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.lodaingRequest = false;
      }
    },
    async getPatientFamilyList() {
      let res = await getPatientFamily();
      this.patientFamilyList = res.data;
    },
  },
  watch: {
    date(newVal) {
      this.$router.push({
        query: {
          ...this.$route.query,
          dt: newVal,
        },
      });
      this.getAvailableTime();
    },
  },
};
</script>
<style scoped>
.custom-btn-group {
  display: flex;
  flex-wrap: wrap;
}
.custom-btn-group .btn {
  flex: 0 auto;
  display: block;
  margin-bottom: 10px;
  border-radius: 0 !important;
}
</style>
