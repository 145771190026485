<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb1
      :title="this.$t('search')"
      :text="this.$t('home')"
      :text1="this.$t('search')"
    />

    <div class="content">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-lg-4 col-xl-3 theiaStickySidebar">
            <!-- Search Filter -->
            <div class="stickysidebar">
              <div class="card search-filter">
                <div class="card-header">
                  <h4 class="card-title mb-0">{{ $t("searchFilter") }}</h4>
                </div>
                <div class="card-body">
                  <div class="filter-widget">
                    <input
                      class="form-control"
                      v-model="searchModel.name"
                      type="text"
                      :placeholder="$t('searchDoctorsName')"
                    />
                  </div>
                  <div class="filter-widget">
                    <input
                      class="form-control"
                      type="date"
                      v-model="searchModel.date"
                      :min="currentDate"
                    />
                  </div>
                  <div class="filter-widget">
                    <h4>{{ $t("selectSpecialist") }}</h4>
                    <div class="specialist-container custom-scroll">
                      <div v-for="(item, i) in specialist" :key="i">
                        <label class="custom_check">
                          <input
                            :value="item.id"
                            type="radio"
                            name="select_specialist"
                            v-model="searchModel.specialists"
                          />
                          <span class="checkmark"></span> {{ item.lang }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="btn-search">
                    <b-button
                      type="button"
                      class="btn w-100"
                      @click="resetSearch"
                    >
                      {{ $t("search") }}
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="search-container custom-scroll col-md-12 col-lg-8 col-xl-9"
          >
            <searchlist :searchResult="searchResult"></searchlist>
          </div>
          <div class="load-more text-center" v-if="totalPages > current_page">
            <button
              style="width: 140px; padding: 10px"
              class="btn btn-primary"
              @click="loadMorePage()"
            >
              <span v-if="loading" class="spinner-border" role="status"> </span>
              <span v-else> {{ $t("loadMore") }} </span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <footerindex></footerindex>
  </div>
</template>
<script>
import { getSpecialists } from "@/api/lookups";
import { getDoctorSearchList } from "@/api/patient";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
      specialist: [],
      searchModel: {
        date: "",
        specialists: "",
        name: "",
        page: 1,
      },
      current_page: 1,
      pageNumber: 1,
      totalCount: 0,
      totalPages: 0,
      searchResult: [],
    };
  },
  computed: {
    ...mapGetters(["currentDate"]),
  },
  created() {
    this.getspecialists();
    this.loadSearchModelFromQuery();
    this.getDataList();
  },
  methods: {
    async getspecialists() {
      let res = await getSpecialists();
      this.specialist = res.data;
    },
    loadSearchModelFromQuery() {
      const query = this.$route.query;
      if (query) {
        this.searchModel.date = query.date;
        this.searchModel.page = query.page;
        this.searchModel.name = query.name || "";
        this.searchModel.specialists = query.specialists;
      }
    },
    async getDataList() {
      this.loading = true;
      try {
        const response = await getDoctorSearchList(this.searchModel);

        if (response) {
          this.searchResult = [...this.searchResult, ...response.data.data];
          this.current_page = response.data.meta.current_page;
          this.totalPages = response.data.meta.last_page;
          this.updateQueryParams();
        }
      } catch (error) {
        console.error("Error fetching roles list:", error);
      } finally {
        this.loading = false;
      }
    },
    updateQueryParams() {
      this.$router.push({
        query: {
          date: this.searchModel.date,
          name: this.searchModel.name,
          specialists: this.searchModel.specialists,
          page: this.searchModel.page,
        },
      });
    },
    loadMorePage() {
      this.searchModel.page++;
      this.getDataList();
    },
    resetSearch() {
      this.searchModel.page = 1;
      this.searchResult = [];
      this.getDataList();
    },
  },
};
</script>
<style scoped>
.specialist-container {
  max-height: 200px;
}
.search-container {
  max-height: 100vh;
}
.custom-scroll {
  overflow-y: scroll;
}
.custom-scroll::-webkit-scrollbar {
  width: 5px;
  transition: 0.3s;
}

.custom-scroll::-webkit-scrollbar-track {
  background: transparent;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background: transparent;
}
.custom-scroll:hover::-webkit-scrollbar-thumb {
  background: #eee;
}
</style>
