<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb1
      :title="$t('myAppointments')"
      :text="$t('home')"
      :text1="$t('myAppointments')"
    />

    <div class="content doctor-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-xl-3 theiaStickySidebar">
            <!-- Profile Sidebar -->
            <profilesidebar></profilesidebar>
            <!-- /Profile Sidebar -->
          </div>

          <div class="col-lg-8 col-xl-9">
            <div class="d-flex justify-content-center py-5" v-if="lodaing">
              <div
                class="spinner-border"
                style="width: 3rem; height: 3rem"
                role="status"
              ></div>
            </div>
            <div class="" v-else-if="!lodaing && appointmentsList.length">
              <div class="dashboard-header">
                <h3>{{ $t("myAppointments") }}</h3>
              </div>
              <p class="text-info">{{ $t("appointmentNote") }}</p>
              <div class="custom-table">
                <div class="table-responsive">
                  <table class="table table-center mb-0">
                    <thead>
                      <tr>
                        <th>{{ $t("doctor") }}</th>
                        <th>{{ $t("day") }}</th>
                        <th>{{ $t("date") }}</th>
                        <th>{{ $t("start_time") }}</th>
                        <th>{{ $t("end_time") }}</th>
                        <th>{{ $t("action") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in appointmentsList"
                        :key="item.time_from"
                      >
                        <td>
                          <router-link
                            class="text-blue-600"
                            to="/doctor-profile"
                            >{{ item?.doctor?.name }}</router-link
                          >
                        </td>
                        <td>
                          {{ item.day }}
                        </td>
                        <td>{{ item.date }}</td>
                        <td>
                          {{ item.time_from }}
                        </td>
                        <td>
                          {{ item.time_to }}
                        </td>
                        <td>
                          <a
                            v-if="item?.meeting_link"
                            class="btn bg-info"
                            target="_blank"
                            :href="item.meeting_link"
                          >
                            <img
                              style="width: 23px"
                              src="@/assets/img/icons/customer-service-1.svg"
                              alt=""
                            />
                          </a>
                          <a
                            @click="handleDetails(item)"
                            class="btn bg-info mx-2"
                            href="#account_details"
                            data-bs-toggle="modal"
                          >
                            <img
                              style="width: 23px"
                              src="@/assets/img/icons/eyecare-icon-03.svg"
                              alt=""
                            />
                          </a>
                          <router-link
                            v-if="item.status == 'done' && !item.is_reviewed"
                            class="btn bg-info"
                            :to="`/appointment-review?id=${item.id}`"
                          >
                            <img
                              style="width: 23px"
                              src="@/assets/img/icons/comment-icon.svg"
                              alt=""
                            />
                          </router-link>
                          <a
                            @click="(activeId = item.id), (open = true)"
                            class="btn bg-info mx-2"
                          >
                            <img
                              style="width: 23px"
                              src="@/assets/img/icons/up-img-1.svg"
                              alt=""
                            />
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <pagination
                :pagination="pagination"
                @changePage="gotoPage"
              ></pagination>
            </div>
            <div class="no-data" v-else>
              {{ $t("noSearchResult") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <footerindex></footerindex>
    <Teleport to="body">
      <div
        class="modal fade custom-modal custom-modal-two modal-lg"
        id="account_details"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{ $t("appointment_details") }}</h5>
              <button type="button" data-bs-dismiss="modal" aria-label="Close">
                <span><i class="fa-solid fa-x"></i></span>
              </button>
            </div>
            <div class="modal-body">
              <div
                class="d-flex justify-content-center py-5"
                v-if="showLodaing"
              >
                <div
                  class="spinner-border"
                  style="width: 3rem; height: 3rem"
                  role="status"
                ></div>
              </div>
              <div v-else>
                <div class="row">
                  <div class="col-md-6 h-100">
                    <h4 class="text-info">{{ $t("patient_name") }}</h4>
                    <p>
                      {{ appointmentDetails.patient_name }}
                    </p>
                  </div>
                  <div
                    class="col-md-6 h-100"
                    v-if="appointmentDetails?.patient_family?.name"
                  >
                    <h4 class="text-info">{{ $t("beneficiaryName") }}</h4>
                    <p>
                      {{ appointmentDetails?.patient_family?.name }} -
                      {{ appointmentDetails?.patient_family?.relative_degree }}
                    </p>
                  </div>

                  <div class="col-md-6 h-100">
                    <h4 class="text-info">{{ $t("patient_name") }}</h4>
                    <p>
                      {{ appointmentDetails.patient_name }}
                    </p>
                  </div>
                  <div class="col-md-6 h-100">
                    <h4 class="text-info">{{ $t("doctor_name") }}</h4>
                    <p>
                      {{ appointmentDetails?.doctor?.name }}
                    </p>
                  </div>
                  <div class="col-md-6 h-100">
                    <h4 class="text-info">{{ $t("day") }}</h4>
                    <p>
                      {{ appointmentDetails?.day }}
                    </p>
                  </div>
                  <div class="col-md-6 h-100">
                    <h4 class="text-info">{{ $t("date") }}</h4>
                    <p>
                      {{ appointmentDetails?.date }}
                    </p>
                  </div>
                  <div class="col-md-6 h-100">
                    <h4 class="text-info">{{ $t("status") }}</h4>
                    <p>
                      {{ appointmentDetails?.status }}
                    </p>
                  </div>
                  <div class="col-md-6 h-100">
                    <h4 class="text-info">{{ $t("diagnosis") }}</h4>
                    <p>
                      {{ appointmentDetails?.diagnosis || "-" }}
                    </p>
                  </div>
                  <div
                    class="col-md-6 h-100"
                    v-if="appointmentDetails?.appointment_medicines?.length"
                  >
                    <h4 class="text-info">{{ $t("medicines") }}</h4>
                    <p
                      v-for="(
                        item, i
                      ) in appointmentDetails.appointment_medicines"
                    >
                      <span class="num"> {{ i + 1 }} </span>
                      {{ item.medicine }} -
                      <small class="text-secondary">
                        {{ item.frequency_per_day }}
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Teleport>
    <Teleport to="body">
      <a-modal
        v-model:open="open"
        centered
        width="75%"
        :footer="null"
        @cancel="cancelUpload"
      >
        <div>
          <h5 class="mb-3">
            <label for="attachment-upload">
              {{ $t("upload.instructions") }}
            </label>
          </h5>
          <div class="upload-file" style="cursor: pointer">
            <img src="@/assets/img/icons/up-img-blue.svg" class="icon-upload" />
            <input
              id="attachment-upload"
              multiple
              type="file"
              accept="image/png,image/jpeg,application/pdf"
              @change="handlefileUpload($event)"
            />
          </div>
          <div class="file-container">
            <div v-for="(file, i) in files" :key="i" @click="removeFile(i)">
              {{ file.name }}
              <a class="text-danger"><i class="fa-solid fa-trash-can"></i></a>
            </div>
          </div>
          <div class="">
            <b-button
              v-if="files.length"
              :disabled="fileLoad"
              class="btn btn-primary btn-lg"
              @click="submitForm"
            >
              <span v-if="!fileLoad">{{ $t("submit") }}</span>
              <b-spinner v-else></b-spinner>
            </b-button>
            <b-button
              type="button"
              @click.prevent="cancelUpload"
              :disabled="fileLoad"
              class="btn btn-info btn-lg mx-3"
            >
              <span>{{ $t("cancel") }}</span>
            </b-button>
          </div>
        </div>
      </a-modal>
    </Teleport>
  </div>
</template>
<script>
import {
  MyAppointments,
  showAppointment,
  appointmentsAttachments,
} from "@/api/patient";
import pagination from "@/components/frontend/pagination/index.vue";
export default {
  data() {
    return {
      open: false,
      lodaing: false,
      fileLoad: false,
      appointmentsList: [],
      pagination: {
        pageNumber: 1,
        totalCount: 0,
        totalPages: 0,
        per_page: 0,
      },
      showLodaing: false,
      appointmentDetails: {},
      activeId: "",
      files: [],
    };
  },
  name: "My-Appointments",
  created() {
    this.getMyAppointments();
  },
  components: {
    pagination,
  },
  methods: {
    removeFile(index) {
      this.files.splice(index, 1);
    },
    handlefileUpload(event) {
      this.files = [...event.target.files];
    },
    async submitForm() {
      this.fileLoad = true;
      try {
        const fd = new FormData();

        fd.append(`appointment_id`, this.activeId);
        this.files.forEach((file, i) => {
          fd.append(`files[${i}]`, file);
        });

        let res = await appointmentsAttachments(fd);
        if (res) {
          this.cancelUpload();
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.fileLoad = false;
      }
    },
    cancelUpload() {
      this.open = false;
      this.files = [];
      console.log("fsd");
    },
    showMeetingLink(item) {
      return true;
      const currentDateTime = new Date();
      const meetingDateTime = new Date(`${item.date} ${item.time_from}`);

      meetingDateTime.setMinutes(meetingDateTime.getMinutes() - 15);

      return item.meeting_link && currentDateTime > meetingDateTime;
    },
    async getMyAppointments() {
      this.lodaing = true;
      try {
        let response = await MyAppointments();
        if (response) {
          this.appointmentsList = response.data.data;
          this.pagination.totalPages = response.data.meta.last_page;
          this.pagination.totalCount = response.data.meta.total;
          this.pagination.pageNumber = response.data.meta.current_page;
          this.pagination.per_page = response.data.meta.per_page;
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.lodaing = false;
      }
    },
    gotoPage(page) {
      this.pagination.pageNumber = Number(page);
      this.$router.push({ query: { ...this.$route.query, page: page } });
      this.getMyAppointments();
    },
    async handleDetails(item) {
      this.showLodaing = true;
      try {
        let response = await showAppointment(item.id);
        if (response) {
          this.appointmentDetails = response.data;
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.showLodaing = false;
      }
    },
  },
};
</script>
<style scoped>
.no-data {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
  padding: 20px 10px;
  text-align: center;
}
.num {
  background: #009efb;
  min-width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 100px;
  text-align: center;
  line-height: 20px;
  color: #fff;
  font-size: 12px;
}

.file-container {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;
}
.file-container div {
  padding: 10px;
  background: #eee;
  margin: 5px;
  border-radius: 5px;
  position: relative;
  transition: 0.3s;
}
.file-container div:hover {
  color: #eee;
  cursor: pointer;
}
.file-container div a {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: 0.3s;
}
.file-container div:hover a {
  opacity: 1;
}
</style>
