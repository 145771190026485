<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb1
      :title="$t('appointmentReview')"
      :text="$t('home')"
      :text1="$t('appointmentReview')"
    />
    <div class="content">
      <div class="container">
        <form @submit.prevent="sendForm" class="col-lg-6 col-md-8 mx-auto">
          <div
            v-for="(question, index) in questionsList"
            :key="index"
            class="mb-2"
          >
            <label>{{ question.question }}</label>
            <div class="mb-3">
              <template v-if="question.type === 'text'">
                <textarea
                  type="text"
                  v-model="question.answer"
                  class="form-control mt-3"
                  style="resize: none"
                />
                <div
                  v-if="
                    v$.$error &&
                    v$.questionsList.$each.$response.$data[index].answer
                      .$invalid
                  "
                  class="text-warning mt-2"
                >
                  {{ $t("validation.requiredField") }}
                </div>
              </template>
            </div>
            <template v-if="question.type === 'numeric'">
              <div class="mb-3">
                <div class="star-rated">
                  <i
                    style="font-size: 25px"
                    class="fa-solid fa-star"
                    v-for="(star, i) in 5"
                    :key="i"
                    @click="question.answer = i + 1"
                    :class="{ selected: question.answer >= i + 1 }"
                  ></i>
                  <span class="mx-2">( {{ question.answer }} ) </span>
                </div>
                <div
                  v-if="
                    v$.$error &&
                    v$.questionsList.$each.$response.$data[index].answer
                      .$invalid
                  "
                  class="text-warning mt-2"
                >
                  {{ $t("validation.requiredField") }}
                </div>
              </div>
            </template>
          </div>
          <div class="py-3">
            <button
              @click.prevent="sendForm"
              :disabled="formLoading"
              class="btn btn-primary px-5 py-2"
            >
              <span v-if="!formLoading">{{ $t("submit") }}</span>
              <b-spinner v-else></b-spinner>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <footerindex></footerindex>
</template>
<script>
import { reviewsQuestions, storeReview } from "@/api/patient";
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      lodaing: false,
      formLoading: false,
      questionsList: [],
    };
  },
  validations: {
    questionsList: {
      $each: helpers.forEach({
        answer: { required },
      }),
    },
  },
  created() {
    this.getQuestions();
  },
  methods: {
    async getQuestions() {
      this.loading = true;
      try {
        const id = this.$route.query.id;
        let response = await reviewsQuestions(id);

        if (response && response.data) {
          this.questionsList = response.data.map((el) => ({
            ...el,
            answer: el.type == "numeric" ? 1 : "",
          }));
        } else {
          this.questionsList = [];
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    async sendForm() {
      this.v$.$touch();
      if (this.v$.$error) return;
      try {
        this.formLoading = true;
        const id = this.$route.query.id;

        const payload = new FormData();
        this.questionsList.forEach((el, i) => {
          payload.append(`question_id[${i}]`, el.id);
          payload.append(`answer[${i}]`, el.answer);
        });
        const res = await storeReview(id, payload);
        if (res) {
          this.$router.go(-1);
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.formLoading = false;
      }
    },
  },
};
</script>
<style scoped>
i {
  cursor: pointer;
}
i.selected {
  color: gold;
}
</style>
