import { createApp } from "vue";
import { router } from "./router";
import App from "./App.vue";
import store from "./store";
import i18n from "./i18n";

import { BootstrapVue3, BToastPlugin } from "bootstrap-vue-3";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/reset.css";
import VueSelect from "vue3-select2-component";
import VCalendar from "v-calendar";
import VueFeather from "vue-feather";
import DatePicker from "vue3-datepicker";
import VueApexCharts from "vue3-apexcharts";
import Vue3Autocounter from "vue3-autocounter";
import VueTelInput from "vue3-tel-input";
import VueEasyLightbox from "vue-easy-lightbox";
import Multiselect from "vue-multiselect";

// plugins
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@fortawesome/fontawesome-free/css/fontawesome.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "vue3-tel-input/dist/vue3-tel-input.css";
import vue3GoogleLogin from "vue3-google-login";

/***********************************************************************/
/* Frontend */
/***********************************************************************/

//Breadcrumb
import Breadcrumb from "@/components/frontend/breadcrumb/aboutus.vue";
import DoctorsSidebar from "@/views/frontend/layouts/doctorsidebar.vue";
import Breadcrumb1 from "@/components/frontend/breadcrumb/breadcrumb1.vue";
import Mainmenu from "@/components/frontend/mainmenu.vue";
import Mainnav from "@/components/frontend/mainnav.vue";
import Profilewidget from "@/components/frontend/profilewidget.vue";

//pages
import Header from "@/views/frontend/layouts/header.vue";
import Headerinfo from "@/views/frontend/layouts/header-info.vue";
import Scrolltotop from "@/views/frontend/layouts/scrolltotop.vue";
import ProfileSidebar from "@/views/frontend/layouts/profilesidebar.vue";

import Footer from "@/views/frontend/layouts/footer.vue";
import IndexBanner from "@/views/frontend/pages/home/indexbanner.vue";
import IndexWork from "@/views/frontend/pages/home/indexwork.vue";
import IndexApp from "@/views/frontend/pages/home/indexapp.vue";
import IndexTestimonial from "@/views/frontend/pages/home/indextestimonial.vue";
import IndexPartners from "@/views/frontend/pages/home/indexpartners.vue";

import AppointmentMedications from "@/views/frontend/pages/doctors/appointments/appointment-medications.vue";

//Patient Pages
import Searchlist from "@/views/frontend/pages/patients/searchdoctor/searchlist.vue";
import Profile_Documentation from "@/views/frontend/pages/patients/doctorprofile/profile-documentation.vue";
import Doctorwidget from "@/views/frontend/pages/patients/doctors/maplist/doctorwidget.vue";
import Refund_Request from "@/views/frontend/pages/patients/accounts/refund-request.vue";
import Account_Request from "@/views/frontend/pages/patients/accounts/account-request.vue";
import Health_Records from "@/views/frontend/pages/patients/dashboard/health-records.vue";
import Dashboard_Analytics from "@/views/frontend/pages/patients/dashboard/dashboard-analytics.vue";
import Dashboard_Appointment from "@/views/frontend/pages/patients/dashboard/dashboard-appointment.vue";
import Dashboard_Reports from "@/views/frontend/pages/patients/dashboard/dashboard-reports.vue";
import Profile_Insurence from "@/views/frontend/pages/patients/doctorprofile/profile-insurence.vue";
import Profile_Availability from "@/views/frontend/pages/patients/doctorprofile/profile-availability.vue";
import Profile_Awards from "@/views/frontend/pages/patients/doctorprofile/profile-awards.vue";

//Doctors
import DoctorSidebar from "@/views/frontend/layouts/doctorsidebar.vue";
import Accounts_Tab from "@/views/frontend/pages/doctors/accounts/accounts-tab.vue";
import Refund_Request_Tab from "@/views/frontend/pages/doctors/accounts/refund-request-tab.vue";
import OnBoardingSidebar from "@/views/frontend/pages/onboard/onboarding-sidebar.vue";
import OnBoardingSlider from "@/views/frontend/pages/onboard/onboarding-slider.vue";
import MyPatientsActive from "@/views/frontend/pages/doctors/patients/my-patients-active.vue";
import MyPatientsInactive from "@/views/frontend/pages/doctors/patients/my-patients-inactive.vue";
import PatientProfilePrescription from "@/views/frontend/pages/doctors/patients/prescription.vue";
import PatientProfileMedical from "@/views/frontend/pages/doctors/patients/medical-records.vue";
import PatientProfileBilling from "@/views/frontend/pages/doctors/patients/billing.vue";

// Modal

import DoctorRequestModal from "@/components/frontend/modal/doctor-request-modal.vue";
import AppointmentCancelModal from "@/components/frontend/modal/appointment-cancel-modal.vue";
import CompletedAppointmentModal from "@/components/frontend/modal/completed-appointment-modal.vue";
import MyPatientsModal from "@/components/frontend/modal/my-patients-modal.vue";
import PatientProfileModal from "@/components/frontend/modal/patient-profile-modal.vue";
import Doctor_Payment_Modal from "@/components/frontend/modal/doctor-payment-modal.vue";
import Accounts_Modal from "@/components/frontend/modal/accounts-modal.vue";
import Medical_Details_Modal from "@/components/frontend/modal/medical-details-modal.vue";
const app = createApp(App);

// Breadcrumb
app.component("breadcrumb", Breadcrumb);
app.component("breadcrumb1", Breadcrumb1);
app.component("doctors-sidebar", DoctorsSidebar);
app.component("mainmenu", Mainmenu);
app.component("mainnav", Mainnav);
app.component("profilewidget", Profilewidget);
//pages
app.component("layoutheader", Header);
app.component("headerinfo", Headerinfo);
app.component("scrolltotop", Scrolltotop);
app.component("footerindex", Footer);
app.component("indexbanner", IndexBanner);
app.component("indexwork", IndexWork);
app.component("indexapp", IndexApp);
app.component("indextestimonial", IndexTestimonial);
app.component("indexpartners", IndexPartners);
app.component("appointment-medications", AppointmentMedications);

//Patient pages
app.component("profilesidebar", ProfileSidebar);
app.component("searchlist", Searchlist);
app.component("profile-documentation", Profile_Documentation);
app.component("doctorwidget", Doctorwidget);
app.component("refund-request", Refund_Request);
app.component("account-request", Account_Request);
app.component("dashboard-reports", Dashboard_Reports);
app.component("profile-insurence", Profile_Insurence);
app.component("profile-availability", Profile_Availability);
app.component("profile-awards", Profile_Awards);
app.component("dashboard-appointment", Dashboard_Appointment);
app.component("dashboard-analytics", Dashboard_Analytics);
app.component("health-records", Health_Records);

//Doctors
app.component("doctorsidebar", DoctorSidebar);
app.component("accounts-tab", Accounts_Tab);
app.component("refund-request-tab", Refund_Request_Tab);
app.component("onboarding-sidebar", OnBoardingSidebar);
app.component("onboarding-slider", OnBoardingSlider);
app.component("my-patients-active", MyPatientsActive);
app.component("my-patients-inactive", MyPatientsInactive);
app.component("patient-profile-prescription", PatientProfilePrescription);
app.component("patient-profile-medical", PatientProfileMedical);
app.component("patient-profile-billing", PatientProfileBilling);

// Modal
app.component("delete-request-modal", DoctorRequestModal);
app.component("appointment-cancel-modal", AppointmentCancelModal);
app.component("completed-appointment-modal", CompletedAppointmentModal);
app.component("my-patients-modal", MyPatientsModal);
app.component("patient-profile-modal", PatientProfileModal);
app.component("medical-details-modal", Medical_Details_Modal);
app.component("doctor-payment-modal", Doctor_Payment_Modal);
app.component("accounts-modal", Accounts_Modal);
app.component("vue3-autocounter", Vue3Autocounter);
app.component("vue-select", VueSelect);
app.component("vue-multiselect", Multiselect);
app.component(VueFeather.name, VueFeather);
app.component("datepicker", DatePicker);

app.use(VueApexCharts);
app.use(store);
app.use(i18n);
app.use(VueEasyLightbox);
app.use(VueTelInput);
app.use(BootstrapVue3);
app.use(BToastPlugin);
app.use(Antd);
app.use(VCalendar, {});
//local "578997975793-2pprg6kum5gu5fonjo694vr58h39r0pp.apps.googleusercontent.com",
app.use(vue3GoogleLogin, {
  clientId:
    "291836546896-o1pblotoho4jjd8oqule0s4nqbja1hcs.apps.googleusercontent.com",
});
app.use(router).mount("#app");
