<template>
  <div id="app">
    <router-view v-if="isStyleLoaded" />

    <!-- <a-modal
      v-model:open="responseMessage.open"
      :title="$t('notice')"
      centered
      :footer="null"
      @cancel="
        responseMessage.open = false;
        responseMessage.message = '';
      "
    >
      {{ responseMessage.message }}
    </a-modal> -->
  </div>
</template>
<script>
import { loadStyles, loadRtlStyles } from "@/loadStyles";
import store from "./store";
import { mapState, mapGetters } from "vuex";
import { notification } from "ant-design-vue";
import { getUserLang } from "@/utils/helpers";
import { showChat } from "@/api/patient";

export default {
  name: "App",
  setup() {
    const lang = getUserLang() || "en";
    if (lang === "ar") {
      loadRtlStyles();
    } else {
      loadStyles();
    }
    store.dispatch("handleAutoLogin");
    store.commit("GET_CURRENT_DATE");
    return {};
  },
  computed: {
    ...mapState(["responseMessage"]),
    ...mapGetters(["isLoggedIn", "userData"]),
  },
  data() {
    return {
      isStyleLoaded: false,
      showPatientChat: null,
    };
  },
  created() {
    setTimeout(() => {
      this.isStyleLoaded = true;
    }, 1000);

    if (this.isLoggedIn && this?.userData?.doctorOrPtient == "isPatient") {
      setInterval(() => {
        this.watchChat();
      }, 30000);
    }
  },
  methods: {
    async watchChat() {
      let res = await showChat();
      this.showPatientChat = res.data.chat;
    },
    loadChatScript() {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.src = "https://embed.tawk.to/6749b5f82480f5b4f5a5bc9c/1idrutl2i";
      script.charset = "UTF-8";
      script.setAttribute("crossorigin", "*");
      script.id = "tawk-to-chat";
      document.body.appendChild(script);
    },
    removeChatScript() {
      const script = document.getElementById("tawk-to-chat");
      if (script) {
        document.body.removeChild(script);
      }
    },
    openNotification() {
      notification.open({
        message: this.$t("notice"),
        description: this.responseMessage.message,
        duration: 2,
        placement: "bottomRight",
      });
    },
  },
  watch: {
    responseMessage(newVal) {
      if (newVal) {
        this.openNotification();
      }
    },
    showPatientChat(newVal) {
      if (newVal) {
        this.loadChatScript();
      } else {
        this.removeChatScript();
      }
    },
  },
};
</script>
