<template>
  <div class="account-page">
    <div class="main-wrapper">
      <layoutheader></layoutheader>

      <!-- Page Content -->
      <div class="content top-space">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-10 mx-auto">
              <!-- Login Tab Content -->
              <div class="account-content">
                <div class="row align-items-center justify-content-center">
                  <div class="col-lg-6 col-md-8 mx-auto">
                    <div class="login-header">
                      <h3>{{ $t("login") }}</h3>
                    </div>
                    <div>
                      <div class="">
                        <div class="mb-3 form-focus">
                          <input
                            type="email"
                            placeholder=" "
                            class="form-control floating"
                            v-model="email"
                          />
                          <label class="focus-label">{{
                            $t("register.email")
                          }}</label>
                        </div>
                        <div class="mb-2" v-if="v$.email.$error">
                          <small
                            v-if="v$.email.required.$invalid"
                            class="text-warning d-block"
                          >
                            {{ $t("validation.requiredField") }}
                          </small>
                          <small
                            v-else-if="v$.email.email.$invalid"
                            class="text-warning d-block"
                          >
                            {{ $t("validation.email") }}
                          </small>
                        </div>
                      </div>
                      <div class="">
                        <div class="mb-3 form-focus">
                          <input
                            v-model="password"
                            type="password"
                            placeholder=" "
                            class="form-control floating"
                          />
                          <label class="focus-label">{{
                            $t("password")
                          }}</label>
                        </div>
                        <div v-if="v$.password.$error">
                          <small
                            v-if="v$.password.required.$invalid"
                            class="text-warning d-block"
                          >
                            {{ $t("validation.requiredField") }}
                          </small>
                        </div>
                      </div>
                      <div class="text-end">
                        <router-link
                          class="forgot-link"
                          to="/forgot-password"
                          >{{ $t("register.forgotPassword") }}</router-link
                        >
                      </div>
                      <b-button
                        class="btn btn-primary w-100 btn-lg login-btn"
                        :disabled="loading"
                        @click="submitForm"
                      >
                        <span v-if="!loading">{{ $t("login") }}</span>
                        <b-spinner v-else></b-spinner>
                      </b-button>
                      <!-- <div class="login-or">
                        <span class="or-line"></span>
                        <span class="span-or">or</span>
                      </div> -->
                      <div class="row social-login pt-2">
                        <!-- <div class="col-6">
                          <a href="javascript:;" class="btn btn-facebook w-100"
                            ><i class="fab fa-facebook-f me-1"></i> Login</a
                          >
                        </div> -->
                        <div class="col-6">
                          <div class="custom-google-login">
                            <div class="google-login">
                              <GoogleLogin :callback="callback" prompt />
                            </div>
                            <div class="google-layout">
                              <span>
                                {{ $t("signInWithGoogle") }}
                              </span>
                              <img src="@/assets/img/google-icon.png" alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="text-center dont-have mt-2">
                        {{ $t("dont_have_account") }}
                        <router-link to="/register">{{
                          $t("registerName")
                        }}</router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Login Tab Content -->
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->

      <footerindex />
    </div>
    <teleport to="body">
      <a-modal
        v-model:open="open"
        :title="$t('notice')"
        centered
        :footer="null"
        @cancel="open = false"
      >
        <div class="mb-4">
          <small class="text-info">
            {{ $t("otpMSG") }}
          </small>
          <div class="pt-2 pb-3">
            <input class="form-control" v-model="code" />
          </div>
          <div class="d-flex justify-content-center">
            <b-button
              class="btn btn-primary btn-lg login-btn d-block w-100"
              @click="handleLoginWithOtp"
            >
              <span>{{ $t("enterOtp") }}</span>
            </b-button>
          </div>
        </div>
      </a-modal>
    </teleport>
  </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import { login, socialLogin } from "@/api/user";
import { decodeCredential } from "vue3-google-login";
export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },

  data() {
    return {
      loginType: 1,
      email: "",
      password: "",
      code: "",
      loading: false,
      open: false,
      isLoggedWithGoogle: false,
      googleUser: {},
      callback: (response) => {
        let user = decodeCredential(response.credential);
        this.handleLogginWithGoogle(user);
      },
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  methods: {
    handleLoginWithOtp() {
      if (this.loginType == 1) {
        this.submitForm();
      } else {
        this.handleLogginWithGoogle();
      }
    },
    async handleLogginWithGoogle(user) {
      this.loginType = 2;
      let payload = new FormData();

      payload.append("given_name", user.given_name);
      payload.append("family_name", user.family_name);
      payload.append("email", user.email);
      payload.append("Picture", user.picture);
      payload.append("driver", "google");
      this.code && payload.append("code", this.code);

      try {
        const res = await socialLogin(payload);
        if (res?.enable_2fa) {
          this.open = true;
          return null;
        }
        this.$store.dispatch("handleLogin", res);
      } catch (error) {
        console.error("Error:", error);
      } finally {
        this.loading = false;
      }
      console.log(user);
    },
    async submitForm() {
      this.loginType = 1;
      this.v$.$touch();
      if (this.v$.$error) {
        return;
      }
      this.loading = true;
      let payload = new FormData();

      payload.append("email", this.email);
      payload.append("password", this.password);
      this.code && payload.append("code", this.code);

      try {
        const res = await login(payload);
        if (res?.enable_2fa) {
          this.open = true;
          return null;
        }
        this.$store.dispatch("handleLogin", res);
      } catch (error) {
        console.error("Error:", error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped>
.custom-google-login {
  background: #fff;
  position: relative;
  height: 50px;
  width: 250px;
  border-radius: 100px;
  border: 1px solid #000;
}
.google-login {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
}
.google-layout {
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.google-layout span {
  margin: 0 7px;
  color: #000;
}
.google-layout img {
  width: 30px;
}
</style>
