<template>
  <div class="">
    <form action="">
      <div class="row">
        <div class="col-12">
          <h3>
            {{ $t("2fa.question") }}
          </h3>
          <div class="dropdown">
            <button
              :disabled="isAlreadyActive"
              class="btn dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style="background: #0e82fd; color: #fff"
            >
              {{ $t("2fa.dropQuestion") }}
            </button>
            <ul
              class="dropdown-menu"
              :class="
                $i18n.locale == 'ar'
                  ? 'dropdown-menu-end'
                  : 'dropdown-menu-start'
              "
            >
              <li>
                <a class="dropdown-item" @click.prevent="isType = 1">{{
                  $t("2fa.byEmail")
                }}</a>
              </li>
              <li>
                <a class="dropdown-item" @click.prevent="isType = 2">{{
                  $t("2fa.byAuthenticator_apps")
                }}</a>
              </li>
            </ul>
          </div>
        </div>
        <!-- first type -->

        <div class="col-12 mb-3">
          <div class="d-flex align-items-start my-3" v-if="isType == 1">
            <input id="sentMail" type="checkbox" v-model="useEmail" />
            <div class="d-inline-block mx-3">
              <label for="sentMail">
                <span v-if="isAlreadyActive">{{
                  $t("2fa.disableByEmailLabel")
                }}</span>
                <span v-else> {{ $t("2fa.byEmailLabel") }}</span>
              </label>
              <div class="mb-3">
                <span class="text-danger" v-if="isAlreadyActive && useEmail"
                  >( {{ $t("2fa.cuation") }} )</span
                >
                <span v-if="!isAlreadyActive">
                  ( {{ $t("2fa.youWillAsked") }} )</span
                >
              </div>
              <div v-if="useEmail">
                <input
                  type="text"
                  class="form-control"
                  :placeholder="$t('enterOtp')"
                  v-model="otp"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-12"><hr /></div> -->

        <!-- second type -->
        <div class="col-12" v-if="isType == 2">
          <div class="row">
            <div class="col-12">
              <div class="d-flex align-items-start my-3">
                <input id="sentMail" type="checkbox" v-model="useOTP" />
                <div class="d-inline-block mx-3">
                  <label for="sentMail">
                    <span v-if="isAlreadyActive">{{
                      $t("2fa.disableLabel")
                    }}</span>
                    <span v-else> {{ $t("2fa.byAuthLabel") }}</span>
                  </label>
                  <div class="mb-3">
                    <span class="text-danger" v-if="isAlreadyActive && useOTP"
                      >( {{ $t("2fa.cuation") }} )</span
                    >
                    <span v-if="!isAlreadyActive">
                      ( {{ $t("2fa.scanQrCode") }} )</span
                    >
                  </div>
                  <div v-if="useOTP">
                    <input
                      type="text"
                      class="form-control"
                      :placeholder="$t('enterOtp')"
                      v-model="otp"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <p v-html="authSettings?.instructions"></p>
            </div>
            <div class="col-md-6 mb-3">
              <p v-html="authSettings?.qr"></p>
            </div>
          </div>
        </div>
        <div class="col-12" v-if="useEmail || useOTP">
          <button
            :disabled="!otp"
            type="button"
            @click.prevent="save"
            class="btn btn-primary btn-lg login-btn"
          >
            {{ $t("submit") }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { getTwoFactorAuth, getCodeByEmail, setTwoFactorAuth } from "@/api/user";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
      authSettings: {},
      enable: false,
      authenticator_apps: false,
      otp: "",
      isType: 0,
      useEmail: false,
      useOTP: false,
      isAlreadyActive: false,
    };
  },
  mounted() {
    this.getTwoFactorAuthSetting();
  },
  computed: {
    ...mapGetters(["userData"]),
  },
  methods: {
    async getTwoFactorAuthSetting() {
      this.loading = true;
      this.error = null;
      try {
        const response = await getTwoFactorAuth();
        if (response) {
          this.authSettings = response.data["2fa"];
          this.authenticator_apps = this.authSettings.authenticator_apps;

          this.enable = this.authSettings.enable;
          if (this.enable) {
            this.isType = 1; // 'activeBymail'
            this.isAlreadyActive = true;
          } else if (this.authenticator_apps) {
            this.isType = 2; // 'active by authenticator_apps'
            this.isAlreadyActive = true;
          } else {
            this.isType = 0;
            this.isAlreadyActive = false;
          }
        }
      } catch (err) {
        console.error(
          "Error fetching two-factor authentication settings:",
          err
        );
      } finally {
        this.loading = false;
      }
    },
    async getOTPtByMail() {
      try {
        const payload = {
          email: this.userData.email,
        };
        const response = await getCodeByEmail(payload);
        if (response) {
          console.log("Response:", response);
        }
      } catch (err) {
        console.error(
          "Error fetching two-factor authentication settings:",
          err
        );
      } finally {
        this.loading = false;
      }
    },
    async save() {
      try {
        const payload = {
          enable_2fa: this.isType === 1 && !this.isAlreadyActive ? 1 : 0,
          enable_authenticator_apps:
            this.isType === 2 && !this.isAlreadyActive ? 1 : 0,
          code: this.otp,
        };
        const response = await setTwoFactorAuth(payload);
        if (response) {
          this.$router.push("/");
        }
      } catch (err) {
        console.error(
          "Error fetching two-factor authentication settings:",
          err
        );
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    useEmail(newVal) {
      this.otp = "";
      if (newVal) {
        this.getOTPtByMail();
      }
    },
    useOTP() {
      this.otp = "";
    },
    isType() {
      this.useEmail = false;
      this.useOTP = false;
    },
  },
};
</script>
<style scoped>
input[type="checkbox"] {
  width: 25px;
  height: 25px;
  transform: translateY(7px);
}
</style>
