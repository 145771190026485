<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb1
      :title="this.$t('appointment_details')"
      :text="this.$t('home')"
      :text1="this.$t('appointment_details')"
    />

    <!-- Page Content -->
    <div class="content">
      <div class="container">
        <div class="row">
          <doctorsidebar></doctorsidebar>

          <div class="col-lg-8 col-xl-9">
            <div class="dashboard-header">
              <div class="header-back">
                <router-link to="/appointments" class="back-arrow"
                  ><i class="fa-solid fa-arrow-left"></i
                ></router-link>
                <h3>{{ $t("appointment_details") }}</h3>
              </div>
            </div>
            <div class="appointment-details-wrap">
              <!-- Appointment Detail Card -->
              <div class="appointment-wrap appointment-detail-card">
                <ul>
                  <li>
                    <div class="patinet-information">
                      <a>
                        <img
                          :src="appointmentDetails?.patient?.photo"
                          :alt="appointmentDetails?.patient_name"
                        />
                      </a>
                      <div class="patient-info">
                        <h6>
                          {{ appointmentDetails?.patient_name }}
                        </h6>
                        <div class="mail-info-patient">
                          <ul>
                            <li>
                              <i class="fa-solid fa-envelope"></i>
                              {{ appointmentDetails?.patient?.gender }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="appointment-action">
                    <div class="detail-badge-info">
                      <span class="badge bg-yellow">{{
                        appointmentDetails.status
                      }}</span>
                    </div>
                  </li>
                </ul>
                <ul class="detail-card-bottom-info justify-content-start">
                  <li>
                    <h6>{{ $t("appointment_date") }}</h6>
                    <span
                      >{{ appointmentDetails.day }} -
                      {{ appointmentDetails.date }}</span
                    >
                  </li>
                  <li class="mx-4">
                    <h6>{{ $t("time") }}</h6>
                    <span>
                      {{ appointmentDetails.time_from }} -
                      {{ appointmentDetails.time_to }}</span
                    >
                  </li>
                </ul>
              </div>
              <!-- /Appointment Detail Card -->

              <div class="create-appointment-details">
                <div class="create-details-card">
                  <div class="create-details-card-head">
                    <div class="card-title-text">
                      <h5>{{ $t("patient_info") }}</h5>
                    </div>
                    <div class="patient-info-box">
                      <div class="row">
                        <div
                          class="col-12"
                          v-if="appointmentDetails?.patient?.chronic_diseases"
                        >
                          <ul class="info-list">
                            <li class="text-info">
                              {{ $t("chronic_diseases") }}
                            </li>
                            <li>
                              <h6>
                                {{
                                  appointmentDetails?.patient?.chronic_diseases
                                }}
                              </h6>
                            </li>
                          </ul>
                          <hr />
                        </div>
                        <div
                          class="col-12"
                          v-if="
                            appointmentDetails?.patient?.current_medications
                          "
                        >
                          <ul class="info-list">
                            <li class="text-info">
                              {{ $t("current_medications") }}
                            </li>
                            <li>
                              <h6>
                                {{
                                  appointmentDetails?.patient
                                    ?.current_medications
                                }}
                              </h6>
                            </li>
                          </ul>
                          <hr />
                        </div>
                        <div
                          class="col-12"
                          v-if="appointmentDetails?.patient?.endorsement"
                        >
                          <ul class="info-list">
                            <li class="text-info">{{ $t("endorsement") }}</li>
                            <li>
                              <h6>
                                {{ appointmentDetails?.patient?.endorsement }}
                              </h6>
                            </li>
                          </ul>
                          <hr />
                        </div>
                        <div
                          class="col-12"
                          v-if="appointmentDetails?.patient?.healthy_habits"
                        >
                          <ul class="info-list">
                            <li class="text-info">
                              {{ $t("healthy_habits") }}
                            </li>
                            <li>
                              <h6>
                                {{
                                  appointmentDetails?.patient?.healthy_habits
                                }}
                              </h6>
                            </li>
                          </ul>
                          <hr />
                        </div>
                        <div
                          class="col-12"
                          v-if="appointmentDetails?.patient?.laboratory_tests"
                        >
                          <ul class="info-list">
                            <li class="text-info">
                              {{ $t("laboratory_tests") }}
                            </li>
                            <li>
                              <h6>
                                {{
                                  appointmentDetails?.patient?.laboratory_tests
                                }}
                              </h6>
                            </li>
                          </ul>
                          <hr />
                        </div>
                        <div
                          class="col-12"
                          v-if="appointmentDetails?.patient?.medical_allergies"
                        >
                          <ul class="info-list">
                            <li class="text-info">
                              {{ $t("medical_allergies") }}
                            </li>
                            <li>
                              <h6>
                                {{
                                  appointmentDetails?.patient?.medical_allergies
                                }}
                              </h6>
                            </li>
                          </ul>
                          <hr />
                        </div>
                        <div
                          class="col-12"
                          v-if="appointmentDetails?.patient?.physical_activity"
                        >
                          <ul class="info-list">
                            <li class="text-info">
                              {{ $t("physical_activity") }}
                            </li>
                            <li>
                              <h6>
                                {{
                                  appointmentDetails?.patient?.physical_activity
                                }}
                              </h6>
                            </li>
                          </ul>
                          <hr />
                        </div>
                        <div
                          class="col-12"
                          v-if="appointmentDetails?.patient?.previous_surgeries"
                        >
                          <ul class="info-list">
                            <li class="text-info">
                              {{ $t("previous_surgeries") }}
                            </li>
                            <li>
                              <h6>
                                {{
                                  appointmentDetails?.patient
                                    ?.previous_surgeries
                                }}
                              </h6>
                            </li>
                          </ul>
                          <hr />
                        </div>
                        <div
                          class="col-12"
                          v-if="appointmentDetails?.attachments"
                        >
                          <ul class="info-list">
                            <li class="text-info">{{ $t("attachments") }}</li>
                            <li
                              v-for="(
                                attachment, i
                              ) in appointmentDetails?.attachments"
                              :key="'attachment' + i"
                            >
                              <h6>
                                <a target="_blank" :href="attachment.file">
                                  {{ $t("attachment") }} ( {{ i + 1 }} )
                                </a>
                              </h6>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="create-details-card-body">
                    <form action="javascript:void(0)">
                      <div class="start-appointment-set">
                        <div class="form-bg-title">
                          <h5>{{ $t("diagnosis") }}</h5>
                        </div>
                        <div class="input-block input-block-new">
                          <textarea
                            v-model="diagnosis"
                            class="form-control"
                            rows="3"
                          ></textarea>
                          <!-- <div class="mb-2" v-if="v$.diagnosis.$error">
                            <small
                              v-if="v$.diagnosis.required.$invalid"
                              class="text-warning d-block"
                            >
                              {{ $t("validation.requiredField") }}
                            </small>
                          </div> -->
                        </div>
                        <div class="input-block input-block-new">
                          <div class="form-bg-title">
                            <h5>{{ $t("medicines") }}</h5>
                          </div>
                          <div class="row">
                            <div class="col-12 position-relative">
                              <input
                                class="form-control"
                                v-model="keyword"
                                type="text"
                                @input="getMedicinesList"
                                :placeholder="$t('filter_medicines')"
                              />
                              <a
                                v-if="keyword"
                                @click="keyword = ''"
                                class="text-danger-icon remove"
                                ><i class="fa-solid fa-xmark"></i
                              ></a>
                              <div
                                class="filter-list"
                                v-if="
                                  keyword &&
                                  filteredSearchedMediciensList.length
                                "
                              >
                                <ul>
                                  <li
                                    v-for="item in filteredSearchedMediciensList"
                                    :key="item.id"
                                    @click="updateSelectedMediciens(item)"
                                  >
                                    <span class="mx-2 ellipses">
                                      {{ item.lang }}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div class="col-12" v-if="selectedMedicines.length">
                              <div
                                class="row py-3"
                                v-for="item in selectedMedicines"
                                :key="item.medicine_id"
                              >
                                <div class="col-4 position-relative">
                                  <span>{{ item.name }}</span>
                                  <a
                                    @click="removeMedicine(item.name)"
                                    class="text-danger-icon"
                                    ><i class="fa-solid fa-xmark"></i
                                  ></a>
                                  <br />
                                </div>
                                <div class="col-4">
                                  <textarea
                                    v-model="item.frequency_per_day"
                                    class="form-control"
                                    rows="3"
                                    :placeholder="$t('dosage')"
                                  ></textarea>
                                </div>
                                <div class="col-4">
                                  <textarea
                                    v-model="item.note"
                                    class="form-control"
                                    rows="3"
                                    :placeholder="$t('notes')"
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="input-block input-block-new">
                          <div class="form-bg-title">
                            <h5>{{ $t("requests") }}</h5>
                          </div>
                          <div class="row">
                            <div class="col-12">
                              <select
                                v-model="requestItem"
                                class="form-control"
                                @change="updateSelectedRequested()"
                              >
                                <option value="">
                                  {{
                                    requestsList.length
                                      ? $t("select")
                                      : $t("nothing_to_select")
                                  }}
                                </option>
                                <option
                                  v-for="item in requestsList"
                                  :key="item.id"
                                  :value="item"
                                >
                                  {{ item.name }}
                                </option>
                              </select>
                            </div>

                            <div class="col-12" v-if="selectedRequested.length">
                              <div
                                class="row py-3"
                                v-for="item in selectedRequested"
                                :key="item.id"
                              >
                                <div class="col-4 position-relative">
                                  <span>{{ item.type }}</span>
                                  <a
                                    @click="removeRequest(item.type)"
                                    class="text-danger-icon"
                                    ><i class="fa-solid fa-xmark"></i
                                  ></a>
                                  <br />
                                </div>
                                <div class="col-8">
                                  <textarea
                                    v-model="item.description"
                                    class="form-control"
                                    rows="3"
                                    :placeholder="$t('description')"
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="start-appointment-set">
                        <div class="form-bg-title">
                          <h5>{{ $t("status") }}</h5>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <select class="form-control" v-model="status">
                              <option :value="null" selected disabled>
                                {{ $t("select") }}
                              </option>
                              <option
                                v-for="(item, i) in statusList"
                                :key="i"
                                :value="item"
                              >
                                {{ item }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-4">
                        <div class="form-set-button">
                          <button
                            @click="$router.go(-1)"
                            class="btn btn-light"
                            type="button"
                          >
                            {{ $t("cancel") }}
                          </button>
                          <button
                            @click="save"
                            class="btn btn-primary"
                            type="button"
                          >
                            <!-- :disabled="loading" -->
                            <span v-if="!loading">{{ $t("save") }}</span>
                            <b-spinner v-else></b-spinner>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footerindex></footerindex>
  </div>
</template>

<script>
import {
  getDoctorAppointmentDetails,
  updateDoctorAppointments,
} from "@/api/doctor";
import { appointmentRequestTypes, medicines } from "@/api/lookups";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },

  data() {
    return {
      appointmentDetails: {},
      lodaing: false,
      statusList: ["request", "accepted", "done"],
      requestsList: [],
      selectedRequested: [],
      searchedMediciensList: [],
      selectedMedicines: [],
      keyword: "",
      requestDescription: "",
      requestItem: {},
      diagnosis: "",
      status: "",
    };
  },
  // validations: {
  // diagnosis: {
  //   required,
  // },
  // },
  created() {
    this.getDetails();
    this.getRequestList();
  },
  computed: {
    filteredSearchedMediciensList() {
      const selectedMedicineNames = new Set(
        this.selectedMedicines.map((medicine) => medicine.name.toLowerCase())
      );

      return this.searchedMediciensList.filter((medicine) => {
        const medicineLangLower = medicine.lang.toLowerCase();
        return (
          medicineLangLower.includes(this.keyword.toLowerCase()) &&
          !selectedMedicineNames.has(medicineLangLower)
        );
      });
    },
  },
  methods: {
    async getMedicinesList() {
      try {
        let response = await medicines(this.keyword);
        if (response) {
          this.searchedMediciensList = response.data;
        }
      } catch (err) {
        console.error(err);
      }
    },
    updateSelectedMediciens(item) {
      let obj = {
        name: item.lang,
        medicine_id: item.id,
        frequency_per_day: item.frequency_per_day || "",
        note: item.note || "",
      };
      this.selectedMedicines.push(obj);

      setTimeout(() => {
        this.searchedMediciensList = this.searchedMediciensList.filter(
          (el) => el.lang !== item.lang
        );
      }, 100);
    },
    removeMedicine(name) {
      const removedMedicineIndex = this.selectedMedicines.findIndex(
        (el) => el.name === name
      );

      if (removedMedicineIndex !== -1) {
        const removedMedicine = this.selectedMedicines[removedMedicineIndex];
        this.searchedMediciensList.push({
          id: removedMedicine.medicine_id,
          lang: removedMedicine.name,
        });

        this.selectedMedicines.splice(removedMedicineIndex, 1);
      }
    },
    updateSelectedRequested() {
      const item = this.requestItem;
      let obj = {
        type: item.name,
        id: item.id,
        description: item?.description || "",
      };
      this.selectedRequested.push(obj);

      setTimeout(() => {
        this.requestsList = this.requestsList.filter(
          (el) => el.name !== item.name
        );
      }, 100);
    },
    removeRequest(name) {
      const removedRequested = this.selectedRequested.find(
        (el) => el.type === name
      );

      this.selectedRequested = this.selectedRequested.filter(
        (el) => el.type !== name
      );

      if (removedRequested) {
        this.requestsList.push({
          id: removedRequested.type,
          name: removedRequested.type,
        });
      }
    },
    async getDetails() {
      this.showLoading = true;
      const id = this.$route.query.app;

      try {
        let response = await getDoctorAppointmentDetails(id);
        if (response) {
          this.appointmentDetails = response.data;
          this.status = this.appointmentDetails?.status;
          this.diagnosis = this.appointmentDetails?.diagnosis || "";

          const requests = this.appointmentDetails?.appointment_requests || [];
          requests.forEach((element) => {
            this.requestItem = {
              name: element.type,
              id: element.id,
              description: element.description,
            };
            this.updateSelectedRequested();
          });

          const medicines =
            this.appointmentDetails?.appointment_medicines || [];
          medicines.forEach((item) => {
            let obj = {
              lang: item.medicine,
              id: item.id,
              frequency_per_day: item.frequency_per_day,
              note: item.notes || "",
            };
            this.updateSelectedMediciens(obj);
          });
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.showLoading = false;
      }
    },
    async getRequestList() {
      try {
        let response = await appointmentRequestTypes();
        if (response) {
          this.requestsList = response.data;
        }
      } catch (err) {
        console.error(err);
      }
    },
    async save() {
      // this.v$.$touch();
      // if (this.v$.$error) {
      //   return;
      // }
      console.log("test");
      const id = this.$route.query.app;
      this.lodaing = true;
      const requests = this.selectedRequested.map((item) => {
        return {
          type: item.id, // id is the same type without translated
          description: item?.description || "",
        };
      });
      try {
        let payload = {
          status: this.status,
          diagnosis: this.diagnosis,
          requests,
          medicines: this.selectedMedicines,
        };
        let res = await updateDoctorAppointments(payload, id);
        if (res) {
          this.$router.go(-1);
        }
      } catch (error) {
        console.error("Error fetching roles list:", error);
      } finally {
        this.lodaing = false;
      }
    },
  },
};
</script>
<style scoped>
.filter-list {
  position: absolute;
  background: #fff;
  z-index: 9;
  max-height: 200px;
  overflow-y: scroll;
  width: 95%;
}
.filter-list li {
  padding: 3px 0;
  margin: 3px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
}
.filter-list::-webkit-scrollbar {
  width: 7px;
}

.filter-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.filter-list::-webkit-scrollbar-thumb {
  background: #888;
}

.filter-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.remove {
  cursor: pointer;
  position: absolute;
  top: 13px;
  right: 20px;
  background: #b50c0c;
  height: 20px;
  width: 20px;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
  color: #fff;
}
</style>
