<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb1
      :title="$t('addBeneficiary')"
      :text="$t('home')"
      :text1="$t('addBeneficiary')"
    />

    <div class="content">
      <div class="container">
        <div class="row">
          <!-- Profile Sidebar -->
          <div class="col-lg-4 col-xl-3 theiaStickySidebar">
            <profilesidebar></profilesidebar>
          </div>

          <div class="col-lg-8 col-xl-9">
            <form @submit.prevent="submitForm">
              <h5>{{ $t("addBeneficiary") }}</h5>
              <div class="py-3 row">
                <div class="col-lg-6">
                  <div class="form-wrap">
                    <label class="col-form-label">{{
                      $t("register.name")
                    }}</label>
                    <input
                      type="text"
                      placeholder=" "
                      class="form-control floating"
                      v-model="relative.name"
                      @blur="v$.relative.name.$touch()"
                    />
                    <div class="py-2" v-if="v$.relative.name.$error">
                      <small
                        v-if="v$.relative.name.required.$invalid"
                        class="text-warning d-block"
                      >
                        {{ $t("validation.requiredField") }}
                      </small>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-wrap">
                    <label class="col-form-label">{{
                      $t("register.email")
                    }}</label>
                    <input
                      type="email"
                      placeholder=" "
                      class="form-control floating"
                      v-model="relative.email"
                      @blur="v$.relative.email.$touch()"
                    />
                    <div class="py-2" v-if="v$.relative.email.$error">
                      <small
                        v-if="v$.relative.email.required.$invalid"
                        class="text-warning d-block"
                      >
                        {{ $t("validation.requiredField") }}
                      </small>
                      <small
                        v-else-if="v$.relative.email.email.$invalid"
                        class="text-warning d-block"
                      >
                        {{ $t("validation.email") }}
                      </small>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="form-wrap">
                    <label class="col-form-label">{{
                      $t("register.relative_degree")
                    }}</label>
                    <select
                      @blur="v$.relative.relative_degree.$touch()"
                      class="form-control"
                      v-model="relative.relative_degree"
                    >
                      <option
                        v-for="(item, i) in relativeList"
                        :key="i"
                        :value="item.value"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                    <div class="py-2" v-if="v$.relative.relative_degree.$error">
                      <small
                        v-if="v$.relative.relative_degree.required.$invalid"
                        class="text-warning d-block"
                      >
                        {{ $t("validation.requiredField") }}
                      </small>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-wrap mb-0">
                    <label class="col-form-label">{{
                      $t("upload.photo")
                    }}</label>
                    <div class="upload-file">
                      <input
                        type="file"
                        accept="image/png,image/jpeg,application/pdf"
                        @change="onFileChange"
                      />
                      <p>
                        {{
                          relative.id_photo
                            ? relative.id_photo.name
                            : $t("upload.instructions")
                        }}
                      </p>
                    </div>
                  </div>

                  <div class="py-2" v-if="v$.relative.id_photo.$error">
                    <small
                      v-if="v$.relative.id_photo.required.$invalid"
                      class="text-warning d-block"
                    >
                      {{ $t("validation.requiredField") }}
                    </small>
                    <small
                      v-if="v$.relative.id_photo.maxFileSize.$invalid"
                      class="text-warning d-block"
                    >
                      {{ $t("maxFileSizeIs3MB") }}
                    </small>
                  </div>
                </div>
              </div>
              <button type="submit" class="btn btn-primary" :disabled="loading">
                <b-spinner v-if="loading"></b-spinner>
                <span v-else>{{ $t("submit") }}</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <footerindex></footerindex>
  </div>
</template>

<script>
import { required, email } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { relativeDegrees } from "@/api/lookups";
import { objectToFormData } from "@/utils/helpers";
import { patientFamily } from "@/api/patient";
export default {
  name: "addRelative",
  components: {},
  data() {
    return {
      lodaing: false,
      relative: {
        name: "",
        email: "",
        relative_degree: "",
        id_photo: null,
      },
      relativeList: [],
    };
  },
  validations() {
    return {
      relative: {
        name: { required },
        email: { required, email },
        relative_degree: { required },
        id_photo: {
          required,
          maxFileSize: (value) => {
            if (!value) return true;
            return value.size <= 3 * 1024 * 1024; // 3MB
          },
        },
      },
    };
  },
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  created() {
    this.getRelative();
  },
  methods: {
    async getRelative() {
      const res = await relativeDegrees();
      this.relativeList = res.data;
    },
    onFileChange(event) {
      const file = event.target.files[0];
      this.relative.id_photo = file;
      this.v$.relative.id_photo.$touch();
    },
    async submitForm() {
      this.v$.$touch();
      if (this.v$.$error) {
        return;
      }
      this.lodaing = true;
      const fd = objectToFormData({
        ...this.relative,
      });

      try {
        let res = await patientFamily(fd);
        this.$router.push("/beneficiaries-list");
      } catch (error) {
        console.log(error);
      } finally {
        this.lodaing = false;
      }
    },
  },
};
</script>
