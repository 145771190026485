<template>
  <div class="">
    <section class="banner-section">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="banner-content aos" data-aos="fade-up">
              <h1>
                {{ $t("hospital.title") }}
                <span class="text-white">{{ $t("hospital.subtitle") }}</span>
              </h1>
            </div>
            <div
              class="search-box-one aos"
              data-aos="fade-up"
              v-if="isLoggedIn && userData.doctorOrPtient == 'isPatient'"
            >
              <form @submit.prevent="submitForm">
                <div class="">
                  <div class="search-input search-line">
                    <div class="form-group mb-0 border">
                      <input
                        v-model="searchModel.name"
                        type="text"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="search-input search-map-line">
                    <div class="form-group mb-0 border custom-select">
                      <select
                        v-model="searchModel.specialists"
                        class="form-control border-0"
                      >
                        <option :value="null">
                          {{ $t("selectSpecialists") }}
                        </option>
                        <option
                          :value="item.id"
                          :key="i"
                          v-for="(item, i) in specialists"
                        >
                          {{ item.lang }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="search-input search-calendar-line">
                    <div class="mb-0 border">
                      <input
                        class="form-control"
                        type="date"
                        v-model="searchModel.date"
                        :min="currentDate"
                      />
                    </div>
                  </div>
                  <div class="form-search-btn">
                    <b-button class="btn" @click="handleSearch">
                      {{ $t("search") }}
                    </b-button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="banner-img aos" data-aos="fade-up">
              <img
                style="opacity: 0"
                src="@/assets/img/banner-img.png"
                class="img-fluid"
                alt="patient-image"
              />
              <div class="banner-img1" style="opacity: 0">
                <img
                  src="@/assets/img/banner/banner-img3.svg"
                  class="img-fluid"
                  alt="checkup-image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import IndexBanner from "@/assets/json/indexbanner.json";
import "vue3-carousel/dist/carousel.css";
import { getSpecialists } from "@/api/lookups";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      searchModel: {
        date: "",
        specialists: null,
        name: "",
      },
      specialists: [],
      IndexBanner: IndexBanner,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        991: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 6,
          snapAlign: "start",
        },
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
    this.getspecialists();
    this.searchModel.date = this.currentDate;
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  methods: {
    async getspecialists() {
      let res = await getSpecialists();
      this.specialists = res.data;
    },
    handleSearch() {
      this.$router.push({
        path: "/search",
        query: {
          name: this.searchModel.name,
          specialists: this.searchModel.specialists,
          date: this.searchModel.date,
          page: 1,
        },
      });
    },
  },
  computed: {
    ...mapGetters(["isLoggedIn", "userData", "currentDate"]),
    dynamicBackground() {
      return `url(./banner-sec-bg-13.jpg)`;
    },
  },
};
</script>
<style scoped>
/* .banner-section {
  background-image: none !important;
} */
.banner-section::after {
  background-image: linear-gradient(45deg, #048aa7, #46a8be) !important;
}
</style>
