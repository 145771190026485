<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb1
      :title="$t('articles')"
      :text="$t('home')"
      :text1="$t('articles')"
    />

    <div class="page-wrapper">
      <!-- Page Content -->
      <div class="content">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <!-- Blog Post -->
              <div
                class="blog"
                v-for="(item, i) in searchResult"
                :key="item.id"
              >
                <div
                  class="align-items-center"
                  :class="i % 2 == 0 ? 'row' : 'flex-row-reverse d-flex'"
                >
                  <div class="col-lg-6">
                    <div class="blog-image">
                      <router-link :to="`/blog-details?blogId=${item.id}`"
                        ><img
                          class="img-fluid"
                          :src="item.image"
                          :alt="item.lang"
                      /></router-link>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <h3 class="blog-title">
                      <router-link :to="`/blog-details?blogId=${item.id}`">{{
                        item.lang
                      }}</router-link>
                    </h3>
                    <div class="blog-content">
                      <p v-html="item.sub_description"></p>
                      <router-link
                        :to="`/blog-details?blogId=${item.id}`"
                        class="read-more"
                      >
                        {{ $t("showMore") }}
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Blog Pagination -->
              <div class="row">
                <div class="col-md-12">
                  <pagination
                    :pagination="pagination"
                    @changePage="gotoPage"
                  ></pagination>
                </div>
              </div>

              <!-- /Blog Pagination -->
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->
    </div>
    <footerindex></footerindex>
  </div>
</template>

<script>
import { blogsSearch } from "@/api/general";
import pagination from "@/components/frontend/pagination/index.vue";

export default {
  data() {
    return {
      title: "Blog List",
      text: "Home",
      text1: "Blog List",
      pagination: {
        pageNumber: 1,
        totalCount: 0,
        totalPages: 0,
        per_page: 0,
      },
      searchResult: [],
      searchModel: {
        page: 1,
        category_id: 1,
      },
    };
  },
  components: {
    pagination,
  },
  created() {
    this.loadSearchModelFromQuery();
    this.getDataList();
  },
  methods: {
    loadSearchModelFromQuery() {
      const query = this.$route.query;
      if (query) {
        this.searchModel.page = Number(query.page);
        this.searchModel.category_id = Number(query.category_id);
      }
    },
    async getDataList() {
      this.loading = true;

      try {
        const response = await blogsSearch(this.searchModel);

        if (response && response.data) {
          this.searchResult = response.data.data;
          this.pagination.totalPages = response.data.meta.last_page;
          this.pagination.totalCount = response.data.meta.total;
          this.pagination.pageNumber = response.data.meta.current_page;
          this.pagination.per_page = response.data.meta.per_page;
          this.updateQueryParams();
        } else {
          console.error("Unexpected response structure:", response);
        }
      } catch (error) {
        console.error("Error fetching data list:", error);
      } finally {
        this.loading = false;
      }
    },
    updateQueryParams() {
      this.$router.push({
        query: {
          page: this.searchModel.page,
          category_id: this.searchModel.category_id,
        },
      });
    },
    gotoPage(page) {
      const pageNumber = Number(page);
      if (pageNumber > 0 && pageNumber <= this.pagination.totalPages) {
        this.pagination.pageNumber = pageNumber;
        this.$router.push({
          query: { ...this.$route.query, page: pageNumber },
        });
        this.getDataList();
      } else {
        console.warn("Invalid page number:", pageNumber);
      }
    },
  },
  watch: {
    "$route.query.category_id": function (newVal, oldVal) {
      console.log("newVal", newVal);
      this.searchModel.category_id = newVal;
      this.searchModel.page = 1;
      this.getDataList();
    },
  },
};
</script>
