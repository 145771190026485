<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb1 :title="$t('faqs')" :text1="$t('faqs')" :text="$t('home')" />
    <div class="page-wrapper">
      <!-- FAQ Section -->
      <section class="faq-inner-page">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="section-inner-header text-center">
                <h2>{{ $t("faqs") }}</h2>
              </div>
            </div>
          </div>
          <div class="faq-info faq-inner-info">
            <div class="accordion" id="faq-details">
              <!-- FAQ Item -->
              <div
                class="accordion-item"
                v-for="(item, index) in dataList"
                :key="index"
              >
                <h2 class="accordion-header" :id="'text' + index">
                  <a
                    href="javascript:void(0)"
                    class="accordion-button collapsed"
                    data-bs-toggle="collapse"
                    :data-bs-target="'#collpase' + index"
                    aria-expanded="false"
                    :aria-controls="'collpase' + index"
                  >
                    {{ item.lang }}
                  </a>
                </h2>
                <div
                  :id="'collpase' + index"
                  class="accordion-collapse collapse"
                  :aria-labelledby="'text' + index"
                  data-bs-parent="#faq-details"
                >
                  <div class="accordion-body">
                    <div class="accordion-content">
                      <p>{{ item.answer }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /FAQ Item -->
            </div>
          </div>
        </div>
      </section>
      <!-- /FAQ Section -->
    </div>
    <footerindex></footerindex>
  </div>
</template>

<script>
import { faqList } from "@/api/general";
export default {
  data() {
    return {
      title: "FAQ",
      text: "Home",
      text1: "FAQ",
      dataList: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      this.showLoading = true;
      const id = this.$route.query.app;

      try {
        let response = await faqList(id);
        if (response) {
          this.dataList = response.data;
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.showLoading = false;
      }
    },
  },
};
</script>
<style scoped>
.faq-info .accordion-button:not(.collapsed)::after,
.faq-info .accordion-button::after {
  display: none;
}
</style>
