<template>
  <!-- Footer -->
  <footer class="footer footer-one">
    <div class="footer-top">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-4">
            <div class="footer-widget footer-about">
              <div class="footer-logo">
                <router-link to="/"
                  ><img
                    src="@/assets/img/Midocean-Digital-Clinic.png"
                    class="img-fluid"
                    style="height: 60px"
                    alt="Logo"
                /></router-link>
              </div>
              <div class="footer-about-content">
                <!-- <p>
                  Effortlessly schedule your medical appointments with
                  midhospital. Connect with healthcare professionals, manage
                  appointments & prioritize your well being
                </p> -->
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="row">
              <div class="col-lg-3 col-md-4">
                <div class="footer-widget footer-menu">
                  <h2 class="footer-title">{{ $t("links") }}</h2>
                  <ul>
                    <li>
                      <router-link to="/">{{ $t("home") }}</router-link>
                    </li>
                    <li>
                      <router-link to="about-us">{{
                        $t("about_us")
                      }}</router-link>
                    </li>
                    <li>
                      <router-link to="faq">{{ $t("faqs") }}</router-link>
                    </li>
                    <li>
                      <router-link to="contact-us">{{
                        $t("contact_us")
                      }}</router-link>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                class="col-lg-3 col-md-4"
                v-if="
                  isLoggedIn &&
                  userData.doctorOrPtient == 'isPatient' &&
                  specialist.length
                "
              >
                <div class="footer-widget footer-menu">
                  <h2 class="footer-title">{{ $t("specialists") }}</h2>
                  <ul>
                    <li v-for="item in specialist.slice(0, 4)" :key="item.lang">
                      <router-link :to="`search?specialists=${item.id}`">{{
                        item.lang
                      }}</router-link>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- <div class="col-lg-6 col-md-4">
                <div class="footer-widget footer-contact">
                  <h2 class="footer-title">{{ $t("contact_us") }}</h2>
                  <div class="footer-contact-info">
                    <div class="footer-address">
                      <p><i class="feather-map-pin"></i> 3556 Beech Street</p>
                    </div>
                    <div class="footer-address">
                      <p><i class="feather-phone-call"></i> +1 315 369 5943</p>
                    </div>
                    <div class="footer-address mb-0">
                      <a href="mailto:midhospital@midocean.edu.km">
                        <i class="feather-mail"></i>midhospital@midocean.edu.km
                      </a>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
          <div class="col-lg-3 col-md-7">
            <div class="footer-widget">
              <!-- <h2 class="footer-title">{{ $t("newsletters") }}</h2>
              <div class="subscribe-form">
                <form action="#">
                  <input
                    type="email"
                    class="form-control"
                    :placeholder="$t('register.email')"
                  />
                  <button type="submit" class="btn">{{ $t("submit") }}</button>
                </form>
              </div> -->
              <h2 class="footer-title">{{ $t("followUs") }}</h2>
              <div class="social-icon">
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/profile.php?id=61567895452626&mibextid=LQQJ4d"
                      target="_blank"
                      ><i class="fab fa-facebook"></i
                    ></a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/midocean_digital_clinic?igsh=NzI1cWZza2Eyd2Q0"
                      target="_blank"
                      ><i class="fab fa-instagram"></i
                    ></a>
                  </li>
                  <li>
                    <a href="https://x.com/MidoceanC" target="_blank"
                      ><i class="fab fa-x"></i
                    ></a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/showcase/midocean-voluntary-clinic/?viewAsMember=true"
                      target="_blank"
                      ><i class="fab fa-linkedin-in"></i
                    ></a>
                  </li>
                  <!--                  <li>-->
                  <!--                    <a-->
                  <!--                      href="https://www.youtube.com/channel/UCNbYV__Y_f8swsahMTdlxVA"-->
                  <!--                      target="_blank"-->
                  <!--                      ><i class="fab fa-youtube"></i-->
                  <!--                    ></a>-->
                  <!--                  </li>-->
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="container">
        <!-- Copyright -->
        <div class="copyright">
          <div class="row">
            <div class="col-md-6 col-lg-6">
              <div class="copyright-text">
                <p class="mb-0">
                  {{ $t("copyright") }} © {{ new Date().getFullYear() }}
                  <a href="https://midocean.ae/" target="_blank">
                    {{ $t("right_reserved") }}
                  </a>
                </p>
              </div>
            </div>
            <!-- <div class="col-md-6 col-lg-6">
              <div class="copyright-menu">
                <ul class="policy-menu">
                  <li>
                    <router-link to="privacy-policy">{{
                      $t("privacy_policy")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link to="terms-condition">{{
                      $t("terms_conditions")
                    }}</router-link>
                  </li>
                </ul>
              </div>
            </div> -->
          </div>
        </div>
        <!-- /Copyright -->
      </div>
    </div>
  </footer>
  <!-- /Footer -->
</template>
<script>
import { getSpecialists } from "@/api/lookups";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      specialist: [],
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn", "userData"]),
  },
  created() {
    this.getspecialists();
  },
  methods: {
    async getspecialists() {
      let res = await getSpecialists();
      this.specialist = res.data;
    },
  },
};
</script>
