<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layoutheader />
    <breadcrumb1
      :title="this.$t('profile_settings')"
      :text="this.$t('home')"
      :text1="this.$t('profile_settings')"
    />
    <div class="content doctor-content">
      <div class="container">
        <div class="row">
          <!-- Profile Sidebar -->
          <doctorsidebar></doctorsidebar>
          <!-- /Profile Sidebar -->
          <div class="col-lg-8 col-xl-9">
            <form action="">
              <div class="setting-card">
                <div class="change-avatar img-upload">
                  <div class="profile-img">
                    <img :src="photoUrl" alt="" />
                  </div>
                  <div class="upload-img">
                    <h5>{{ $t("profile_image") }}</h5>
                    <div class="imgs-load d-flex align-items-center">
                      <div class="change-photo">
                        {{ $t("upload_now") }}
                        <input
                          type="file"
                          class="upload"
                          @change="changePhoto"
                          accept=".jpg,.png,.jpeg,.webp"
                        />
                      </div>
                      <a
                        href="#"
                        class="upload-remove mx-2"
                        @click="removePhoto"
                        >{{ $t("remove") }}</a
                      >
                    </div>
                    <p class="form-text">
                      {{ $t("image_instruction_uploads") }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="setting-title">
                <h5>{{ $t("information") }}</h5>
              </div>
              <div class="row gy-3">
                <div class="col-lg-6">
                  <div class="form-focus h-auto">
                    <input
                      type="text"
                      placeholder=" "
                      class="form-control floating"
                      v-model="doctor.name"
                    />
                    <label class="focus-label">{{ $t("register.name") }}</label>

                    <div class="py-2" v-if="v$.doctor.name.$error">
                      <small
                        v-if="v$.doctor.name.required.$invalid"
                        class="text-warning d-block"
                      >
                        {{ $t("validation.requiredField") }}
                      </small>
                    </div>
                  </div>
                </div>
                <!-- email -->
                <div class="col-lg-6">
                  <div class="form-focus h-auto">
                    <input
                      type="email"
                      class="form-control floating"
                      placeholder=" "
                      v-model="doctor.email"
                    />
                    <label class="focus-label">{{
                      $t("register.email")
                    }}</label>

                    <div class="py-2" v-if="v$.doctor.email.$error">
                      <small
                        v-if="v$.doctor.email.required.$invalid"
                        class="text-warning d-block"
                      >
                        {{ $t("validation.requiredField") }}
                      </small>
                      <small
                        v-else-if="v$.doctor.email.email.$invalid"
                        class="text-warning d-block"
                      >
                        {{ $t("validation.email") }}
                      </small>
                    </div>
                  </div>
                </div>
                <!-- mobile -->
                <div class="col-lg-6">
                  <div class="form-focus h-auto">
                    <input
                      type="text"
                      class="form-control floating"
                      placeholder=" "
                      v-model="doctor.mobile"
                    />
                    <label class="focus-label">{{
                      $t("register.mobile")
                    }}</label>
                    <div class="py-2" v-if="v$.doctor.mobile.$error">
                      <small
                        v-if="v$.doctor.mobile.required.$invalid"
                        class="text-warning d-block"
                      >
                        {{ $t("validation.requiredField") }}
                      </small>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-12 pt-3">
                  <b-button
                    class="btn btn-primary mx-2 btn-lg login-btn"
                    @click="updateData"
                    :disabled="loadingForm"
                  >
                    <span v-if="!loadingForm">{{ $t("update") }}</span>
                    <b-spinner v-else></b-spinner>
                  </b-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <footerindex></footerindex>
  </div>
</template>
<script>
import { getCountries } from "@/api/lookups";
import { getDoctorProfile, updateDoctorProfile } from "@/api/doctor";
import { required, email } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { objectToFormData } from "@/utils/helpers";

export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      title: "Profile Settings",
      text: "Home",
      text1: "Profile Settings",
      lodaing: false,
      doctorDetails: {},
      photoUrl: "",
      loadingForm: false,
      doctor: {
        name: "",
        email: "",
        mobile: null,
        photo: null,
      },
    };
  },
  validations() {
    return {
      doctor: {
        name: { required },
        email: { required, email },
        mobile: { required },
      },
    };
  },
  computed: {
    photo() {
      return this.photoUrl || "";
    },
  },
  created() {
    this.getCountries();
    this.getDetails();
  },
  methods: {
    async updateData() {
      await this.v$.$validate();
      if (this.v$.$invalid) {
        return;
      }
      this.loadingForm = true;
      if (!this.doctor.photo) {
        delete this.doctor.photo;
      }
      const fd = objectToFormData({
        ...this.doctor,
      });
      try {
        let res = await updateDoctorProfile(fd);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingForm = false;
      }
    },
    async getCountries() {
      const countriesList = await getCountries();
      this.countries = countriesList.data;
    },
    async getDetails() {
      this.lodaing = true;
      try {
        let response = await getDoctorProfile();
        if (response) {
          const doctor = response?.data;
          this.doctor.name = doctor.name;
          this.doctor.email = doctor.email || "";
          this.doctor.mobile = doctor.mobile || null;
          this.photoUrl = doctor.photo || null;
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.lodaing = false;
      }
    },
    submitForm() {
      this.$router.push("/profile-settings");
    },
    changePhoto(event) {
      const file = event.target.files[0];
      if (file) {
        this.doctor.photo = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.photoUrl = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    removePhoto() {
      this.doctor.photo = null;
      this.photoUrl = "";
    },
  },
};
</script>
