import request from "@/utils/request";

export function getCountries() {
  return request({
    url: `/countries`,
    method: "get",
  });
}

export function getSpecialists() {
  return request({
    url: `/specialists`,
    method: "get",
  });
}

export function getDaysOfAvailableTime() {
  return request({
    url: `/lookups/days`,
    method: "get",
  });
}

export function appointmentRequestTypes() {
  return request({
    url: `/lookups/appointment-request-types`,
    method: "get",
  });
}

export function medicines(name) {
  return request({
    url: `/medicines?name=${name}`,
    method: "get",
  });
}

export function relativeDegrees() {
  return request({
    url: `/lookups/relative-degrees`,
    method: "get",
  });
}
